import httpService from '../../../adapters/httpService';
import { URL_API } from '../../../configs';
import baseActions from '../baseActions';

const path = 'user/role';

export const actionType = (action: string) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const http = httpService();
const index = {
  ...baseActions(path, URL_API, actionType),
  fetchList: (params?: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchList').request });
      return http.get(`${URL_API}/user/roles/search`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchList').success, response: response.data, params });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchList').error });
          throw error.response;
        },
      );
    };
  },
  fetchAll: (params?: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchAll').request });
      return http.get(`${URL_API}/user/roles/all`, { params: { ...params, status: true } }).then(
        (response) => {
          dispatch({ type: actionType('fetchAll').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchAll').error });
          throw error.response;
        },
      );
    };
  },
  getRolePermissions: (id: number) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('getRolePermissions').request });
      return http.get(`${URL_API}/user/permission/role/${id}`).then(
        (response) => {
          dispatch({ type: actionType('getRolePermissions').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('getRolePermissions').error });
          throw error.response;
        },
      );
    };
  },
  updatePermission: (id: number, data: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('updatePermission').request });
      return http.put(`${URL_API}/user/permission/role/${id}`, data).then(
        (response) => {
          dispatch({ type: actionType('updatePermission').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('updatePermission').error });
          throw error.response;
        },
      );
    };
  },
};
export default index;

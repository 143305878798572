import { FormHelperText } from '@mui/material';
import { Box } from '@mui/system';
import { InputLabel } from '@mui/material';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './index.css';

interface Props {
  files: Array<any>;
  onChange: (file: any) => void;
  accept: any;
  label: string;
  maxSize: number;
  notes?: string;
  error?: boolean;
}

const Dropzone: React.FC<Props> = (props) => {
  const { files, onChange, accept, label, maxSize, notes, error } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    multiple: false,
    maxSize,
    onDrop: (acceptedFiles) => {
      onChange(acceptedFiles.map((file) => file));
    },
  });

  const thumbs = files.map((file, index) => (
    <div
      style={{
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
      }}
      key={`image-dropzone-${index}`}>
      <div
        style={{
          display: 'flex',
          minWidth: 0,
          overflow: 'hidden',
        }}>
        <img
          src={file}
          style={{
            display: 'block',
            width: 'auto',
            height: '100%',
          }}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {thumbs.length > 0 ? (
            <aside
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 16,
              }}>
              {thumbs}
            </aside>
          ) : (
            <p>Drag 'n' drop, or click to select files</p>
          )}
        </div>
        {notes && (
          <p className="text-muted" style={{ marginBottom: 0 }}>
            {notes}
          </p>
        )}
      </section>
      {error && <FormHelperText error>Required field</FormHelperText>}
    </Box>
  );
};

export default Dropzone;

import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';

interface Props {
  placeholder: string;
  initialContent: string;
  onContentChange: (content: string) => void;
}

const TextEditor = ({ placeholder, initialContent, onContentChange }: Props) => {
  const editor = useRef(null);
  const [content, setContent] = useState(initialContent);

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || 'Start typing...',
    }),
    [placeholder],
  );

  const handleBlur = (newContent: string) => {
    setContent(newContent);
    if (onContentChange) {
      onContentChange(newContent);
    }
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      onBlur={handleBlur}
      onChange={() => {}}
    />
  );
};

export default TextEditor;

import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ModalDelete from '../../components/modal/ModalDelete';
import DataTable from '../../components/table/DataTable';
import companyProvider from '../../redux/actions/company-provider';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import CompanyProviderChannel from './CompanyProviderChannel';
import CompanyProviderFormDialog from './CompanyProviderFormDialog';
import { providerColumns } from './models/columns';
import PasswordDialog from './PasswordDialog';
import CompanyProviderFilter from './CompanyProviderFilter';

const CompanyProviderIndex = ({ history, role }: any) => {
  const [dataSelected, setDataSelected] = useState();
  const [openProviderForm, setOpenProviderForm] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.companyProvider);

  useEffect(() => {
    dispatch(companyProvider.fetchList(data.params));
    return () => dispatch(companyProvider.resetParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setDataSelected(undefined);
    setOpenProviderForm(true);
  };
  const handleEdit = (data: any) => {
    setDataSelected(data);
    setOpenProviderForm(true);
  };
  const handleDelete = (data: any) => {
    setDataSelected(data);
    setOpenDelete(true);
  };
  const handleDetail = (data: any) => {
    setDataSelected(data);
  };
  return (
    <Box>
      <Box component="h1" sx={{ mt: 0, fontSize: [22, 28, 32] }}>
        Company Payment Provider
      </Box>
      <Grid container sx={{ mb: 1 }} justifyContent={'space-between'}>
        <Grid item md={3}>
          <CompanyProviderFilter />
        </Grid>
        <Grid item md={3} textAlign={'end'}>
          {role.create && (
            <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
              Add
            </Button>
          )}
        </Grid>
      </Grid>
      <DataTable
        columns={providerColumns(role, handleEdit, handleDetail, handleDelete)}
        data={data}
        height="45vh"
        // disableSelectionOnClick={false}
        // onRowClick={({ row }) => setDataSelected(row)}
        showTotal
        fetchList={companyProvider.fetchList}
      />
      <CompanyProviderFormDialog
        open={openProviderForm}
        handleClose={() => setOpenProviderForm(false)}
        data={dataSelected}
      />
      <PasswordDialog
        open={openPassword}
        handleClose={() => setOpenPassword(false)}
        data={dataSelected}
      />
      {dataSelected && <CompanyProviderChannel companyProvider={dataSelected} role={role} />}
      <ModalDelete
        open={openDelete}
        dataSelected={dataSelected}
        handleClose={() => setOpenDelete(false)}
        deleteData={companyProvider.deleteData}
        fetchList={companyProvider.fetchList}
        data={data}
      />
    </Box>
  );
};

export default CompanyProviderIndex;

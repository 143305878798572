import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string } from 'yup';

export interface IChannelForm {
  name: string;
  payment_channel_type_id: number;
  status: boolean;
  logo_url: string;
  logo_id: number;
}

export const defaultValues = {
  name: '',
  payment_channel_type_id: 0,
  status: true,
  logo_url: '',
  logo_id: 0,
};

export const resolver = yupResolver(
  object({
    name: string().required('Required field'),
    payment_channel_type_id: number()
      .transform((value) => (value === 0 ? undefined : value))
      .required('Required field'),
  }),
);

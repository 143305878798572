//@typescript-eslint/no-unused-vars: 0
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import DataTable from '../../../components/table/DataTable';

const PartnerHistoryDetail = ({ history }: any) => {
  const columns: GridColDef[] = [
    { field: 'trx_date', headerName: 'Transaction Date', flex: 1 },
    { field: 'invoice', headerName: 'Invoice Number', flex: 1 },
    { field: 'sett_date', headerName: 'Settlement Date', flex: 1 },
    { field: 'total_fee', headerName: 'Total Amount', flex: 1 },
    { field: 'status', headerName: 'status', flex: 1 },
  ];
  const data = [
    {
      id: 1,
      trx_date: '12 Desember 2021 09:00 WIB',
      sett_date: '12 Desember 2021 09:00 WIB',
      invoice: 'INV/01/02313',
      total_fee: 120000,
      status: 'Success',
    },
    {
      id: 2,
      trx_date: '12 Desember 2021 09:00 WIB',
      sett_date: '12 Desember 2021 09:00 WIB',
      invoice: 'INV/01/02313',
      total_fee: 120000,
      status: 'Success',
    },
    {
      id: 3,
      trx_date: '12 Desember 2021 09:00 WIB',
      sett_date: '12 Desember 2021 09:00 WIB',
      invoice: 'INV/01/02313',
      total_fee: 120000,
      status: 'Success',
    },
    {
      id: 4,
      trx_date: '12 Desember 2021 09:00 WIB',
      sett_date: '12 Desember 2021 09:00 WIB',
      invoice: 'INV/01/02313',
      total_fee: 120000,
      status: 'Success',
    },
    {
      id: 5,
      trx_date: '12 Desember 2021 09:00 WIB',
      sett_date: '12 Desember 2021 09:00 WIB',
      invoice: 'INV/01/02313',
      total_fee: 120000,
      status: 'Success',
    },
  ];
  return (
    <Box>
      <Box component="h2">CO/RBK/01</Box>
      <DataTable columns={columns} data={data} height="45vh" />
    </Box>
  );
};

export default PartnerHistoryDetail;

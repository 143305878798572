import { FilterAlt, FilterAltOff, Search } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import cashIn from '../../redux/actions/cash-in';
import company from '../../redux/actions/company';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CashInExportDialog from './CashInExportDialog';
import { useMediaQuery, Theme } from '@mui/material';
import channel from '../../redux/actions/channel';
import product from '../../redux/actions/product';

interface Options {
  label: string;
  value: string;
}

const statusList: Options[] = [
  { label: '- All Status -', value: '' },
  { label: 'Reconciled', value: 'reconciled' },
  { label: 'Pending', value: 'pending' },
  { label: 'Paid', value: 'paid' },
  { label: 'Refund', value: 'refund' },
  { label: 'Failed', value: 'failed' },
  { label: 'Expired', value: 'expired' },
  { label: 'Done', value: 'done' },
];

const CashInFilter = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.cashIn);
  const companyList = useAppSelector((state) => state.company.dataAll);
  const [companyId, setCompanyId] = useState(0);
  const [modalExport, setModalExport] = useState<boolean>(false);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  // const [paramsCashIn, setParamsCashIn] = useState(data.params)
  const [selectedPaymentChannel, setSelectedPaymentChannel] = useState<Array<String>>(['']);
  const [selectedProduct, setSelectedProduct] = useState<Array<String>>(['']);
  const [selectedStatus, setSelectedStatus] = useState<Array<String>>(
    statusList.map((val) => val.value),
  );
  const [paymentChannelList, setPaymentChannelList] = useState<Array<Options>>([]);
  const [productList, setProductList] = useState<Array<Options>>([]);

  const handleSearch = debounce((e) => {
    dispatch(cashIn.fetchList({ ...data.params, page: 1, key: null, value: e.target.value }));
  }, 500);

  const [dateValue, setDateValue] = useState<DateRange<Date>>([null, null]);
  const handleFilter = () => {
    if ((dateValue[0] && !dateValue[1]) || (!dateValue[0] && dateValue[1])) {
      return;
    }
    let from = dateValue[0] ? moment(dateValue[0]).format('YYYYMMDD') : '';
    let to = dateValue[1] ? moment(dateValue[1]).format('YYYYMMDD') : '';
    dispatch(cashIn.fetchList({ ...data.params, page: 1, from, to }));
  };

  const handleReset = () => {
    setCompanyId(0);
    setDateValue([null, null]);
    setSelectedStatus(statusList.map((val) => val.value));
    setSelectedPaymentChannel(paymentChannelList.map((val) => val.value));
    setSelectedProduct(productList.map((val) => val.value));
    dispatch(
      cashIn.fetchList({
        ...data.params,
        page: 1,
        to: null,
        from: null,
        value: null,
        statuses: '',
        payment_channel_ids: '',
        company_product_ids: '',
      }),
    );
  };

  const handleChangePaymentChannel = (event: SelectChangeEvent) => {
    const {
      target: { value },
    }: any = event;
    let newValue = value;

    if (value.includes('')) {
      if (value.length === paymentChannelList.length - 1 && selectedPaymentChannel.includes('')) {
        const temp = [...value];
        temp.splice(0, 1);
        newValue = temp;
      } else newValue = paymentChannelList.map((val) => val.value);
    } else if (selectedPaymentChannel.includes('') && !value.includes('')) newValue = [];
    else if (
      !selectedPaymentChannel.includes('') &&
      !value.includes('') &&
      value.length === paymentChannelList.length - 1
    )
      newValue = paymentChannelList.map((val) => val.value);
    else newValue = value;

    setSelectedPaymentChannel(newValue);

    dispatch(
      cashIn.fetchList({
        ...data.params,
        page: 1,
        payment_channel_ids: newValue.includes('') ? '' : newValue.join(),
      }),
    );
  };

  const handleChangeProduct = (event: SelectChangeEvent) => {
    const {
      target: { value },
    }: any = event;
    let newValue = value;

    if (value.includes('')) {
      if (value.length === productList.length - 1 && selectedProduct.includes('')) {
        const temp = [...value];
        temp.splice(0, 1);
        newValue = temp;
      } else newValue = productList.map((val) => val.value);
    } else if (selectedProduct.includes('') && !value.includes('')) newValue = [];
    else if (
      !selectedProduct.includes('') &&
      !value.includes('') &&
      value.length === productList.length - 1
    )
      newValue = productList.map((val) => val.value);
    else newValue = value;
    setSelectedProduct(newValue);

    dispatch(
      cashIn.fetchList({
        ...data.params,
        page: 1,
        company_product_ids: newValue.includes('') ? '' : newValue.join(),
      }),
    );
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    const {
      target: { value },
    }: any = event;
    let newValue = value;

    if (value.includes('')) {
      if (value.length === statusList.length - 1 && selectedStatus.includes('')) {
        const temp = [...value];
        temp.splice(0, 1);
        newValue = temp;
      } else newValue = statusList.map((val) => val.value);
    } else if (selectedStatus.includes('') && !value.includes('')) newValue = [];
    else if (
      !selectedStatus.includes('') &&
      !value.includes('') &&
      value.length === statusList.length - 1
    )
      newValue = statusList.map((val) => val.value);
    else newValue = value;
    setSelectedStatus(newValue);

    dispatch(
      cashIn.fetchList({
        ...data.params,
        page: 1,
        statuses: newValue.includes('') ? '' : newValue.join(),
      }),
    );
  };

  const renderValueFunction = (selected: any[], items: any[]) => {
    if (selected.length === 0) {
      return '';
    }

    if (selected.includes('')) {
      return items[0]?.label;
    }

    const selectedNames = selected.map((value) => {
      const item = items.find((item) => item.value === value);
      return item ? item.label : '';
    });

    return selectedNames.join(', ');
  };

  const sortArray = (a: any, b: any) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  const fetchPaymentChannel = async () => {
    const resChannel: any = await channel.fetchAllList();
    const dataChannel = resChannel.data.map((val: any) => ({ label: val.name, value: val.id }));
    const sortedDataChannel = dataChannel.sort(sortArray);
    setPaymentChannelList([{ label: '- All Payment Channel -', value: '' }, ...sortedDataChannel]);

    setSelectedPaymentChannel(['', ...sortedDataChannel.map((val: any) => val.value)]);
  };

  const fetchProduct = async () => {
    const resProduct: any = await product.fetchAllList();
    const dataProduct = resProduct.data.map((val: any) => ({
      label: val.product_name,
      value: val.id,
    }));
    const sortedDataProduct = dataProduct.sort(sortArray);
    setProductList([{ label: '- All Product Name -', value: '' }, ...sortedDataProduct]);

    setSelectedProduct(['', ...sortedDataProduct.map((val: any) => val.value)]);
  };

  useEffect(() => {
    dispatch(company.fetchAll());
    fetchPaymentChannel();
    fetchProduct();
    return () => dispatch(cashIn.resetParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      cashIn.fetchList({
        ...data.params,
        payment_channel_ids: '',
        company_product_ids: '',
        key: 'company_id',
        value: companyId !== 0 ? companyId : null,
        page: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <Box>
      <Grid container sx={{ mb: 1 }} spacing={1}>
        <Grid item xs={6} md={3}>
          <Select
            size="small"
            fullWidth
            value={companyId}
            onChange={(e) => setCompanyId(parseInt(e.target.value as string))}>
            <MenuItem value={0}>- All Company -</MenuItem>
            {companyList.map((item: any, idx: number) => (
              <MenuItem key={idx} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            displayEmpty
            size="small"
            multiple
            fullWidth
            style={{ maxWidth: '100%' }}
            value={selectedStatus}
            onChange={(val: any) => handleChangeStatus(val)}
            renderValue={(selected) =>
              selected.length > 0 ? renderValueFunction(selected, statusList) : 'No Status Selected'
            }>
            {statusList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {item.label}
                  </Grid>
                  <Grid item xs={6} justifyContent="end" textAlign="end">
                    {selectedStatus.includes(item.value) && <CheckIcon fontSize="small" />}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={6} display="flex">
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DateRangePicker
              value={dateValue}
              onChange={(newValue) => {
                setDateValue(newValue);
              }}
              disableFuture
              inputFormat="DD/MM/YYYY"
              renderInput={(startProps, endProps) => (
                <Fragment>
                  <TextField {...startProps} label="" size="small" />
                  <Box sx={{ mx: 1 }}> to </Box>
                  <TextField {...endProps} sx={{ mr: 1 }} label="" size="small" />
                </Fragment>
              )}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            startIcon={<FilterAlt />}
            onClick={handleFilter}
            sx={{
              mr: 1,
              fontSize: isSmallScreen ? '0.75rem' : '1rem',
              padding: isSmallScreen ? '4px 8px' : '6px 16px',
            }}>
            {!isSmallScreen && 'Filter'}
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<FilterAltOff />}
            onClick={handleReset}
            sx={{
              mr: 1,
              fontSize: isSmallScreen ? '0.75rem' : '1rem',
              padding: isSmallScreen ? '4px 8px' : '6px 16px',
            }}>
            {!isSmallScreen && 'Reset'}
          </Button>
          <Button
            variant="contained"
            startIcon={<InsertDriveFileIcon />}
            onClick={() => setModalExport(true)}
            sx={{
              fontSize: isSmallScreen ? '0.75rem' : '1rem',
              padding: isSmallScreen ? '4px 8px' : '6px 16px',
            }}>
            {!isSmallScreen && 'Export'}
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 1 }} spacing={1}>
        <Grid item xs={6} md={3}>
          <Select
            displayEmpty
            size="small"
            multiple
            fullWidth
            style={{ maxWidth: 600 }}
            value={selectedPaymentChannel}
            onChange={(val: any) => handleChangePaymentChannel(val)}
            renderValue={(selected) =>
              selected.length > 0
                ? renderValueFunction(selected, paymentChannelList)
                : 'No Payment Channel Selected'
            }>
            {paymentChannelList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {item.label}
                  </Grid>
                  <Grid item xs={6} justifyContent="end" textAlign="end">
                    {selectedPaymentChannel.includes(item.value) && <CheckIcon fontSize="small" />}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            displayEmpty
            size="small"
            multiple
            fullWidth
            style={{ maxWidth: 600 }}
            value={selectedProduct}
            onChange={(val: any) => handleChangeProduct(val)}
            renderValue={(selected) =>
              selected.length > 0
                ? renderValueFunction(selected, productList)
                : 'No Partner Selected'
            }>
            {productList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {item.label}
                  </Grid>
                  <Grid item xs={6} justifyContent="end" textAlign="end">
                    {selectedProduct.includes(item.value) && <CheckIcon fontSize="small" />}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          <OutlinedInput
            fullWidth
            onChange={handleSearch}
            placeholder="Search"
            endAdornment={<Search color="disabled" />}
            size="small"
          />
        </Grid>
      </Grid>

      <CashInExportDialog open={modalExport} handleClose={() => setModalExport(false)} />
    </Box>
  );
};

export default CashInFilter;

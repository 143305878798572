import banner from '../../assets/images/loginBanner.svg';
import { Box, Grid } from '@mui/material';
import { Fragment } from 'react';

const Home = ({ history }: any) => {
  return (
    <Fragment>
      <Grid container justifyContent={'center'} mb={2}>
        <Grid item>
          <Box sx={{ fontSize: [22, 32], fontWeight: 'bold' }}>Welcome to payment system</Box>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid item md={6} sm={8}>
          <img src={banner} alt={'Banner'} style={{ width: '100%', height: 'auto' }} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Home;

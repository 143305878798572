import { BorderColor, Delete } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

export const columns = (
  role: any,
  handleEdit: (data: any) => void,
  handleDelete: (data: any) => void,
  history: any,
): GridColDef[] => {
  return [
    { field: 'company_partner', headerName: 'Company Name', width: 270 },
    { field: 'partner', headerName: 'Partner Name', width: 320 },
    { field: 'partner_code', headerName: 'Partner Code', width: 150 },
    // {field: 'phone', headerName: 'Phone Number', width: 150},
    // {field: 'email', headerName: 'Email', width: 230},
    { field: 'payment_channel_name', headerName: 'Payment Channel', width: 150 },
    // {field: 'bank_account_number', headerName: 'Bank Account Number', width: 180},
    // {field: 'account_receivable', headerName: 'Account Receivable', width: 320},
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (data) => (data.row.status ? 'Active' : 'Non Active'),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (data) => (
        <Grid container>
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="primary" />
            </Tooltip>
          )}
          {/* {role.view && <Tooltip title="History" sx={{ mr: 1 }} onClick={() => history.push(`partner/${data.id}/history`)}>
            <History fontSize="small" className="pointer" color="info" />
          </Tooltip>} */}
          {role.delete && (
            <Tooltip title="Delete" onClick={() => handleDelete(data.row)}>
              <Delete fontSize="small" className="pointer" color="error" />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};

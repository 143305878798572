import { Add, Search } from '@mui/icons-material';
import { Button, Grid, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import ModalDelete from '../../components/modal/ModalDelete';
import DataTable from '../../components/table/DataTable';
import product from '../../redux/actions/product';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { alertError } from '../../utils/alert';
// import FormDialog from './FormDialog';
import FormDialogNew from './FormDialogNew';
import { columns } from './models/columns';

const ProductIndex = ({ history, role }: any) => {
  // const [openForm, setOpenForm] = useState(false);
  const [openNewForm, setOpenNewForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataSelected, setDataSelected] = useState({});
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.product);

  useEffect(() => {
    dispatch(product.fetchList(data.params));
    return () => dispatch(product.resetParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setDataSelected({});
    setOpenNewForm(true);
  };
  const handleEdit = async (data: any) => {
    try {
      const res = await product.getDetailChannel(data.id);
      const provider: Array<any> = [];

      if (res.data) {
        for (let i = 0; i < res.data.length; i++) {
          const providerChannel = [];
          for (let j = 0; j < (res.data[i].channels.length || 0); j++) {
            providerChannel.push({
              id: res.data[i].channels[j].id,
              provider_channel_id:
                res.data[i].channels[j].company_payment_provider_channel_mapping_id,
              cash_in_fix: String(
                res.data[i].channels[j].company_payment_provider_channel_mapping_fee_fix_value,
              ),
              cash_in_percentage: String(
                res.data[i].channels[j].company_payment_provider_channel_mapping_fee_percentage,
              ),
              fee_fix_value: String(res.data[i].channels[j].fee_fix_value),
              fee_percentage: String(res.data[i].channels[j].fee_percentage),
              status: res.data[i].channels[j].status,
              disabled: true,
            });
          }
          provider.push({
            provider_channel: providerChannel,
            provider_id: res.data[i].id,
            disabled: true,
          });
        }
      }

      const newData = {
        id: data.id,
        company_id: data.company_id,
        product_name: data.product_name,
        code: data.product_code,
        status: data.status,
        fee_fix_value: data.fee_fix_value,
        fee_percentage: data.fee_percentage,
        cashout_fee_fix_value: data.cashout_fee_fix_value,
        cashout_fee_percentage: data.cashout_fee_percentage,
        provider,
      };

      setDataSelected(newData);
      setOpenNewForm(true);
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };
  const handleDelete = (data: any) => {
    setDataSelected(data);
    setOpenDelete(true);
  };
  const handleSearch = debounce((e) => {
    dispatch(product.fetchList({ ...data.params, page: 1, key: null, value: e.target.value }));
  }, 500);

  return (
    <Box>
      <Box component="h1" sx={{ mt: 0, fontSize: [22, 28, 32] }}>
        Product
      </Box>
      <Grid container>
        <OutlinedInput
          sx={{ mb: 1, mr: 1 }}
          onChange={handleSearch}
          placeholder="Search"
          endAdornment={<Search color="disabled" />}
          size="small"
        />
        {role.create && (
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleAdd()}>
            Add
          </Button>
        )}
      </Grid>
      <DataTable
        columns={columns(role, handleEdit, handleDelete, history)}
        data={data}
        height="60vh"
        fetchList={product.fetchList}
      />
      {/* <FormDialog open={openForm} handleClose={() => setOpenForm(false)} data={dataSelected} /> */}
      <FormDialogNew
        open={openNewForm}
        handleClose={() => setOpenNewForm(false)}
        data={dataSelected}
      />
      <ModalDelete
        open={openDelete}
        dataSelected={dataSelected}
        handleClose={() => setOpenDelete(false)}
        deleteData={product.deleteData}
        fetchList={product.fetchList}
        data={data}
      />
    </Box>
  );
};

export default ProductIndex;

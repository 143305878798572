import React from 'react';
import { styled } from '@mui/material/styles';

interface LabelProps {
  children: React.ReactNode;
  htmlFor?: string;
}

const StyledLabel = styled('label')(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
}));

const Label: React.FC<LabelProps> = ({ children, htmlFor }) => {
  return <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>;
};

export default Label;

import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { alertError, alertSuccess } from '../../utils/alert';
import LoadingButton from '@mui/lab/LoadingButton';
import cashout from '../../redux/actions/cash-out';
import { Save } from '@mui/icons-material';
import { ICashOutExportForm, defaultCashOutExportForm } from './models/form';
import { Box, FormHelperText, InputLabel, TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';

interface Props {
  open: boolean;
  handleClose: () => void;
  id: string;
}

const CashOutExportDialog: React.FC<Props> = (props) => {
  const { open, handleClose, id } = props;
  const [form, setForm] = useState<ICashOutExportForm>({
    ...defaultCashOutExportForm,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ start_date: boolean; end_date: boolean }>({
    start_date: false,
    end_date: false,
  });
  const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);

  const handleChangeDate = (key: string, value: string) => {
    setError((prev) => ({ ...prev, [key]: false }));
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const onSubmit = async () => {
    if (!form.start_date || !form.end_date)
      return setError({
        start_date: !form.start_date,
        end_date: !form.end_date,
      });

    setLoading(true);
    try {
      const payload = {
        start_date: moment(form.start_date).format('YYYY-MM-DD'),
        end_date: moment(form.end_date).format('YYYY-MM-DD'),
        cashout_id: id,
      };

      const res = await cashout.export(payload);

      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Cash Out Report - ${moment().format('DDMMYYYY')}.xlsx`);
      document.body.appendChild(link);
      link.click();

      alertSuccess(`Export Data Berhasil`).then(() => {
        handleClose();
      });
    } catch (error: any) {
      const res: any = JSON.parse(
        String.fromCharCode.apply(null, new Uint8Array(error?.data) as any),
      );
      alertError(res.message || 'Maaf, terjadi kesalahan');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setForm({ ...defaultCashOutExportForm });
      setError({ start_date: false, end_date: false });
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Export Data</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <InputLabel>Start Date</InputLabel>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                open={openStartDate}
                onOpen={() => setOpenStartDate(true)}
                onClose={() => setOpenStartDate(false)}
                value={form.start_date}
                onChange={(value) => handleChangeDate('start_date', value)}
                disableFuture
                inputFormat="DD/MM/YYYY"
                renderInput={(props: any) => (
                  <React.Fragment>
                    <TextField
                      {...props}
                      label=""
                      size="small"
                      style={{ width: '100%' }}
                      onClick={(e) => setOpenStartDate(true)}
                    />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
            {error.start_date && (
              <FormHelperText sx={{ color: '#d32f2f' }}>Required field</FormHelperText>
            )}
          </Box>

          <Box sx={{ mb: 2 }}>
            <InputLabel>End Date</InputLabel>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                open={openEndDate}
                onOpen={() => setOpenEndDate(true)}
                onClose={() => setOpenEndDate(false)}
                value={form.end_date}
                onChange={(value) => handleChangeDate('end_date', value)}
                disableFuture
                minDate={form.start_date || undefined}
                inputFormat="DD/MM/YYYY"
                renderInput={(props: any) => (
                  <React.Fragment>
                    <TextField
                      {...props}
                      label=""
                      size="small"
                      style={{ width: '100%' }}
                      onClick={(e) => setOpenEndDate(true)}
                    />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
            {error.end_date && (
              <FormHelperText sx={{ color: '#d32f2f' }}>Required field</FormHelperText>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleClose} variant="outlined" type="button">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<Save />}
            onClick={onSubmit}
            variant="contained">
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CashOutExportDialog;

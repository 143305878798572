import { Search } from '@mui/icons-material';
import { Grid, OutlinedInput } from '@mui/material';
import { debounce } from 'lodash';
import { Fragment } from 'react';
import companyProviderChannel from '../../redux/actions/company-provider-channel';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const CompanyProviderChannelFilter = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.companyProviderChannel);

  const handleSearch = debounce((e) => {
    dispatch(companyProviderChannel.fetchList({ ...data.params, page: 1, value: e.target.value }));
  }, 500);

  return (
    <Fragment>
      <Grid container sx={{ mb: 1 }} spacing={1}>
        <Grid item md={12}>
          <OutlinedInput
            fullWidth
            onChange={handleSearch}
            placeholder="Search"
            endAdornment={<Search color="disabled" />}
            size="small"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CompanyProviderChannelFilter;

import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridRowParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAppDispatch } from '../../redux/store';
import { Grid, Typography } from '@mui/material';
import { useCallback } from 'react';

interface Props {
  columns: GridColDef[];
  data: any;
  isSelection?: boolean;
  onSelect?: (e: GridSelectionModel, x?: GridCallbackDetails) => void;
  onRowClick?: (e: any) => void;
  height?: string;
  isPagination?: boolean;
  disableSelectionOnClick?: boolean;
  fetchList?: any;
  loading?: boolean;
  isRowSelectable?: (params: GridRowParams<{ [key: string]: any }>) => boolean;
  showTotal?: boolean;
}

export default function DataTable(props: Props): JSX.Element {
  const { isPagination = true, disableSelectionOnClick = true, showTotal } = props;
  const dispatch = useAppDispatch();

  const onPaginationChange = (page: number, limit: number) => {
    if (props.fetchList) {
      dispatch(props.fetchList({ ...props.data?.params, page, limit }));
    }
  };

  const onSortTable = (cell: Array<any>) => {
    if (!props.fetchList) return;

    if (cell.length) {
      dispatch(
        props.fetchList({ ...props.data?.params, order: cell[0].field, sort: cell[0].sort }),
      );
    } else {
      dispatch(props.fetchList({ ...props.data?.params, order: 'created_at', sort: 'desc' }));
    }
  };
  const onFilterChange = (filterModel: GridFilterModel) => {
    if (filterModel.items.length > 0) {
      dispatch(
        props.fetchList({
          ...props.data?.params,
          key: filterModel.items[0].columnField,
          value: filterModel.items[0].value,
        }),
      );
    }
    // eslint-disable-next-line
  };

  return (
    <div style={{ display: 'flex', height: props.height }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={props.data?.dataList || props.data}
          autoHeight={!props.height}
          rowHeight={40}
          columns={props.columns}
          sortingMode={!!props.fetchList ? 'server' : 'client'}
          onSortModelChange={onSortTable}
          checkboxSelection={props.isSelection}
          isRowSelectable={props.isRowSelectable}
          disableSelectionOnClick={disableSelectionOnClick}
          onRowClick={props.onRowClick}
          onSelectionModelChange={props.onSelect}
          filterMode={!!props.fetchList ? 'server' : 'client'}
          onFilterModelChange={onFilterChange}
          loading={props.loading || props.data?.isLoading}
          columnBuffer={1000}
          components={{
            Footer: () =>
              isPagination ? (
                <Grid
                  container
                  alignItems="center"
                  justifyContent={showTotal ? 'space-between' : 'end'}
                  borderTop={'1px solid rgba(224, 224, 224, 1)'}
                  p={1}>
                  {showTotal && (
                    <Typography variant="inherit">
                      Total: {props.data?.pagination?.count || 0}
                    </Typography>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Rows per page:
                    <Select
                      size="small"
                      sx={{ mx: 1 }}
                      value={props.data?.pagination?.recordPerPage || 5}
                      onChange={(e) => onPaginationChange(1, e.target.value)}>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                    <Pagination
                      sx={{ mt: [2, 0] }}
                      count={props.data?.pagination?.lastPage}
                      page={props.data?.pagination?.currentPage}
                      onChange={(e, val) =>
                        onPaginationChange(val, props.data?.pagination?.recordPerPage)
                      }
                      variant="outlined"
                      color="primary"
                    />
                  </div>
                </Grid>
              ) : (
                <div></div>
              ),
          }}
        />
      </div>
    </div>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, SelectField, SwitchField } from '../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../utils/alert';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { channelDefaultValues, channelResolver, IChannelForm } from './models/form';
import LoadingButton from '@mui/lab/LoadingButton';
import { ExpandMore, Key, Save } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import companyProviderChannel from '../../redux/actions/company-provider-channel';
import providerChannel from '../../redux/actions/provider-channel';
import { feeType } from '../product/settings/ref';

const ChannelFormDialog = ({ open, handleClose, data, companyProvider }: any) => {
  const methods = useForm<IChannelForm>({ resolver: channelResolver });
  const [template, setTemplate] = React.useState<Array<any>>([]);
  const isFixVirtualAccount = methods.watch('is_fix_virtual_account');
  const providerCode = methods.watch('provider_code');

  const loading = useAppSelector((state) => state.companyProviderChannel.isLoadingSubmit);
  const params = useAppSelector((state) => state.companyProviderChannel.params);
  const providerChannelList = useAppSelector((state) => state.providerChannel.dataAll);
  const dispatch = useAppDispatch();

  const labelCashType = providerChannelList.some(
    (val: any) => val.code === providerCode && val.capability === 2,
  )
    ? 'Out'
    : 'In';

  const onSubmit = async (value: IChannelForm) => {
    let channelTypeConfigs = null;
    if (template.length > 0 && value.is_fix_virtual_account && labelCashType === 'In') {
      channelTypeConfigs = {};
      for (let i = 0; i < template.length; i++) {
        channelTypeConfigs = {
          ...channelTypeConfigs,
          [template[i].name]: String(template[i].name).includes('is_active')
            ? !!value.channel_type_configs[template[i].name]
            : String(template[i].name).includes('_start') ||
              String(template[i].name).includes('_end')
            ? Number(value.channel_type_configs[template[i].name])
            : value.channel_type_configs[template[i].name],
        };
      }
    }

    const formData = {
      company_id: value.company_id,
      payment_provider_id: value.payment_provider_id,
      provider_code: value.provider_code,
      fee_fix_value: value.fee_fix_value,
      fee_percentage: value.fee_percentage,
      expired_time: labelCashType === 'In' ? value.expired_time * 60 : 0,
      status: value.status,
      channel_type_configs: channelTypeConfigs,
    };
    try {
      if (data?.id) {
        await dispatch(companyProviderChannel.updateData(data?.id, formData));
      } else {
        await dispatch(companyProviderChannel.createData(formData));
      }
      alertSuccess(`Provider Channel berhasil di ${data?.id ? 'update' : 'tambahkan'}`).then(() => {
        handleClose();
        dispatch(companyProviderChannel.fetchList(params));
        fetchProviderChannel();
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  const resetFieldTemplate = () => {
    if (template.length > 0) {
      for (let i = 0; i < template.length; i++) {
        methods.setValue(`channel_type_configs.${template[i].name}`, null);
      }
    } else {
      methods.setValue('channel_type_configs', null);
    }
  };

  React.useEffect(() => {
    if (labelCashType === 'Out') methods.setValue('capability', 2);
    else methods.setValue('capability', 1);

    if (providerCode) {
      const temp: any = providerChannelList.find((val: any) => val.code === providerCode);

      setTemplate(temp?.channel_type_config_templates || []);
    } else if (providerCode === '') {
      setTemplate([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerCode]);

  // React.useEffect(() => {
  //   resetFieldTemplate();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [template]);

  const fetchProviderChannel = () => {
    const params = {
      company_id: companyProvider.company_id,
      is_available: true,
    };

    dispatch(providerChannel.fetchAll(companyProvider?.payment_provider_id, params));
  };

  React.useEffect(() => {
    let parent = {
      capability: data?.cash_in ? 1 : data?.cash_out ? 2 : undefined,
      company_id: companyProvider?.company_id,
      company_name: companyProvider?.company_name,
      payment_provider_id: companyProvider?.payment_provider_id,
      provider_name: companyProvider?.provider_name,
    };
    if (data?.id && open === true) {
      parent = {
        ...parent,
        ...(!!data.channel_type_configs && {
          is_fix_virtual_account: true,
        }),
      };

      const paymentChannelId = providerChannelList.find((val: any) => val.code === data.code);
      methods.reset({
        ...data,
        ...parent,
        provider_code: paymentChannelId?.code || data.code,
        expired_time: data.expired_time / 60,
      });
    } else {
      methods.reset({ ...channelDefaultValues, ...parent });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{data?.id ? 'Edit' : 'Add'} Channel</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <InputField name="capability" type="number" disabled style={{ display: 'none' }} />
              <InputField name="company_name" label="Company Name" disabled />
              <InputField name="provider_name" label="Provider Name" disabled />
              <SelectField
                name="provider_code"
                label="Payment Channel"
                options={providerChannelList.map((item: any) => ({
                  value: item.code,
                  label: `${item.payment_channel_name} (${item.code})`,
                }))}
              />
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <InputField
                    name="fee_fix_value"
                    label="Cash In/Out Fee (Fix)"
                    type="number"
                    min={0}
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputField
                    name="fee_percentage"
                    label="Cash In/Out Fee (%)"
                    type="number"
                    min={0}
                    max={100}
                  />
                </Grid>
              </Grid>
              {labelCashType === 'In' && (
                <InputField
                  name="expired_time"
                  label="Expired Time (Minute)"
                  type="number"
                  min={0}
                />
              )}
              {template.length > 0 && labelCashType === 'In' && (
                <SwitchField name="is_fix_virtual_account" label="Additional Config" />
              )}
              {template.length > 0 && labelCashType === 'In' && isFixVirtualAccount && (
                <Accordion
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.action.disabled}`,
                    borderRadius: 1,
                    '&:before': { display: 'none' },
                    color: 'gray',
                    marginBottom: '16px',
                  }}
                  elevation={0}
                  disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      minHeight: '24px',
                      px: '14px',
                      '.MuiAccordionSummary-content': { my: '8px' },
                    }}>
                    <Key sx={{ mr: 1 }} />
                    Setting Fix Virtual Account
                  </AccordionSummary>
                  <AccordionDetails>
                    {template.map((item: any, idx: number) =>
                      item.type !== 'boolean' ? (
                        <InputField
                          key={idx}
                          name={'channel_type_configs.' + item.name}
                          label={item.name}
                          autocomplete="off"
                          maxLength={item.type === 'integer' ? 10 : 0}
                          pattern={item.type === 'integer' ? /^[0-9]*$/i : undefined}
                        />
                      ) : (
                        <SwitchField name={'channel_type_configs.' + item.name} label={item.name} />
                      ),
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              <SwitchField name="status" label="Aktif" />
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ChannelFormDialog;

import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ModalDelete from '../../components/modal/ModalDelete';
import DataTable from '../../components/table/DataTable';
import companyProviderChannel from '../../redux/actions/company-provider-channel';
import providerChannel from '../../redux/actions/provider-channel';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { alertError } from '../../utils/alert';
import CompanyChannelFormDialog from './CompanyChannelFormDialog';
import { channelColumns } from './models/columns';
import CompanyProviderChannelFilter from './CompanyProviderChannelFilter';

const CompanyProviderChannel = ({ companyProvider, role }: any) => {
  const [dataSelected, setDataSelected] = useState();
  const [openChannelForm, setOpenChannelForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.companyProviderChannel);

  useEffect(() => {
    if (companyProvider) {
      let params = {
        ...data.params,
        company_id: companyProvider.company_id,
        payment_provider_id: companyProvider.payment_provider_id,
      };
      dispatch(companyProviderChannel.fetchList(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProvider]);

  const fetchProviderChannel = async (isNotEdit: boolean = true) => {
    const params = {
      company_id: companyProvider.company_id,
      ...(isNotEdit && {
        is_available: true,
      }),
    };

    await dispatch(providerChannel.fetchAll(companyProvider?.payment_provider_id, params));
  };

  const handleAdd = async () => {
    try {
      await fetchProviderChannel();

      setDataSelected(undefined);
      setOpenChannelForm(true);
    } catch (error) {}
  };
  const handleEdit = async (data: any) => {
    try {
      await fetchProviderChannel(false);
      const res = await companyProviderChannel.fetchDetailNew(data.id);

      setDataSelected({ ...data, ...res.data });
      setOpenChannelForm(true);
    } catch (error: any) {
      alertError(error.response?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };
  const handleDelete = (data: any) => {
    setDataSelected(data);
    setOpenDelete(true);
  };

  return (
    <Box>
      <Box component="h2" sx={{ fontSize: [22, 28, 32] }}>
        {companyProvider.company_name} - {companyProvider.provider_name} Payment Channel
      </Box>
      <Grid container sx={{ mb: 1 }} justifyContent={'space-between'}>
        <Grid item md={3}>
          <CompanyProviderChannelFilter />
        </Grid>
        {role.create && (
          <Grid item md={3} textAlign={'end'}>
            <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
              Add Channel
            </Button>
          </Grid>
        )}
      </Grid>
      <DataTable
        columns={channelColumns(role, handleEdit, handleDelete)}
        data={data}
        height="45vh"
        showTotal
        fetchList={companyProviderChannel.fetchList}
      />
      <CompanyChannelFormDialog
        companyProvider={companyProvider}
        open={openChannelForm}
        handleClose={() => setOpenChannelForm(false)}
        data={dataSelected}
      />
      <ModalDelete
        open={openDelete}
        dataSelected={dataSelected}
        handleClose={() => setOpenDelete(false)}
        deleteData={companyProviderChannel.deleteData}
        fetchList={companyProviderChannel.fetchList}
        data={data}
        callback={fetchProviderChannel}
      />
    </Box>
  );
};

export default CompanyProviderChannel;

import { FilterAlt, FilterAltOff } from '@mui/icons-material';
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import cashOut from '../../redux/actions/cash-out';
import moment from 'moment';
import company from '../../redux/actions/company';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme } from '@mui/material';

const CashOutFilter = ({ history }: any) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.cashOut);
  const companyList = useAppSelector((state) => state.company.dataAll);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [companyId, setCompanyId] = useState(0);
  const [status, setStatus] = useState('');

  useEffect(() => {
    dispatch(company.fetchAll());
    return () => dispatch(cashOut.resetParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      cashOut.fetchList({
        ...data.params,
        key: 'company_id',
        value: companyId !== 0 ? companyId : null,
        page: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const [dateValue, setDateValue] = useState<DateRange<Date>>([null, null]);
  const handleFilter = () => {
    if ((dateValue[0] && !dateValue[1]) || (!dateValue[0] && dateValue[1])) {
      return;
    }
    let from_date = dateValue[0] ? moment(dateValue[0]).format('YYYY-MM-DD') : '';
    let to_date = dateValue[1] ? moment(dateValue[1]).format('YYYY-MM-DD') : '';
    dispatch(cashOut.fetchList({ ...data.params, page: 1, from_date, to_date }));
  };

  const filterStatus = (status: string) => {
    setStatus(status);
    dispatch(cashOut.fetchList({ ...data.params, status, page: 1 }));
  };

  const handleReset = () => {
    setCompanyId(0);
    setStatus('');
    setDateValue([null, null]);
    dispatch(
      cashOut.fetchList({
        ...data.params,
        page: 1,
        to_date: null,
        from_date: null,
        status: '',
        value: null,
      }),
    );
  };

  return (
    <Grid container sx={{ mb: 1 }} spacing={1} justifyContent="space-between">
      <Grid item md={3}>
        <Select
          size="small"
          fullWidth
          value={companyId}
          onChange={(e) => setCompanyId(parseInt(e.target.value as string))}>
          <MenuItem value={0}>- All Company -</MenuItem>
          {companyList.map((item: any, idx: number) => (
            <MenuItem key={idx} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item md={3}>
        <Select
          size="small"
          fullWidth
          displayEmpty
          value={status}
          onChange={(e) => filterStatus(e.target.value)}>
          <MenuItem value="">- All Status -</MenuItem>
          <MenuItem value="ready">Ready</MenuItem>
          <MenuItem value="requested">Requested</MenuItem>
          <MenuItem value="approved">Approved</MenuItem>
          <MenuItem value="failed">Failed</MenuItem>
          <MenuItem value="rejected">Rejected</MenuItem>
          <MenuItem value="done">Done</MenuItem>
        </Select>
      </Grid>
      <Grid item md={6} display="flex" spacing={1}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DateRangePicker
            value={dateValue}
            onChange={(newValue) => {
              setDateValue(newValue);
            }}
            disableFuture
            inputFormat="DD/MM/YYYY"
            renderInput={(startProps, endProps) => (
              <Fragment>
                <TextField {...startProps} label="" size="small" />
                <Box sx={{ mx: 1 }}> to </Box>
                <TextField {...endProps} sx={{ mr: 1 }} label="" size="small" />
              </Fragment>
            )}
          />
        </LocalizationProvider>
        <Button variant="contained" startIcon={<FilterAlt />} onClick={handleFilter} sx={{ mr: 1 }}>
          {!isSmallScreen && 'Filter'}
        </Button>
        <Button
          variant="contained"
          color="error"
          startIcon={<FilterAltOff />}
          onClick={handleReset}>
          {!isSmallScreen && 'Reset'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CashOutFilter;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import cashOut from '../../redux/actions/cash-out';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Box, Button, Grid, OutlinedInput, TextField, Theme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { cashinInvoiceColumns } from './models/columns';
import { FilterAlt, FilterAltOff, Search } from '@mui/icons-material';
import { debounce } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { alertError } from '../../utils/alert';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';
import DataTableDetail from '../../components/table/DataTableDetail';

const CashinInvoiceDialog = ({
  open,
  handleClose,
  partnerId,
  id,
  companyProductId,
  fetchList,
}: any) => {
  const data = useAppSelector((state) => state.cashOut);
  const params = useAppSelector((state) => state.cashOut.paramsDataInvoice);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [dateValue, setDateValue] = React.useState<DateRange<Date>>([null, null]);
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const [listSelected, setListSelected] = React.useState<any[]>([]);
  const [isButtonSelectDisable, setIsButtonSelectDisable] = React.useState<boolean>(true);

  const dispatch = useAppDispatch();

  const fetchData = async (currParams: any) => {
    const paramsInvoice = {
      partner_id: partnerId,
      company_product_id: companyProductId,
      ...currParams,
    };

    try {
      await dispatch(cashOut.fetchListInvoice(paramsInvoice));
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  const onSelectTable = (values: any[]) => setListSelected(values);

  const handleSearch = debounce((e) => fetchData({ search: e.target.value, ...params }), 500);

  const onClose = () => {
    setListSelected([]);
    setDateValue([null, null]);
    handleClose();
  };

  const handleFilter = () => {
    if ((dateValue[0] && !dateValue[1]) || (!dateValue[0] && dateValue[1])) {
      return;
    }

    const settlement_date_start = dateValue[0] ? moment(dateValue[0]).format('YYYY-MM-DD') : '';
    const settlement_date_end = dateValue[1] ? moment(dateValue[1]).format('YYYY-MM-DD') : '';

    fetchData({
      settlement_date_start: settlement_date_start,
      settlement_date_end: settlement_date_end,
      ...params,
    });
  };

  const handleReset = () => {
    setDateValue([null, null]);
    fetchData({ page: 1, limit: 10, order: 'created_at', sort: 'desc' });
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const payload = {
      cash_out_id: id,
      cash_in_item_ids: listSelected,
    };

    try {
      await dispatch(cashOut.addInvoice(payload));
      fetchList();
      onClose();
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    } finally {
      setLoadingSubmit(false);
    }
  };

  React.useEffect(() => {
    dispatch(cashOut.resetParamsInvoice());
    if (open) fetchData({ page: 1, limit: 10, order: 'created_at', sort: 'desc' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (listSelected.length >= 1) {
      setIsButtonSelectDisable(false);
    } else {
      setIsButtonSelectDisable(true);
    }
  }, [listSelected]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowBackIcon style={{ marginRight: 12, cursor: 'pointer' }} onClick={onClose} />
              ADD INVOICE
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 3 }}>
          <Grid item md={8} display="flex" justifyContent="flex-end" spacing={1}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DateRangePicker
                value={dateValue}
                onChange={(newValue) => {
                  setDateValue(newValue);
                }}
                disableFuture
                inputFormat="DD/MM/YYYY"
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} label="" size="small" />
                    <Box sx={{ mx: 1, fontSize: 'medium' }}> to </Box>
                    <TextField {...endProps} sx={{ mr: 1 }} label="" size="small" />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              startIcon={<FilterAlt />}
              onClick={handleFilter}
              sx={{ mr: 1 }}>
              {!isSmallScreen && 'Filter'}
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<FilterAltOff />}
              onClick={handleReset}>
              {!isSmallScreen && 'Reset'}
            </Button>
          </Grid>
          <Grid item sm={4}>
            <OutlinedInput
              fullWidth
              onChange={handleSearch}
              placeholder="Search"
              endAdornment={<Search color="disabled" />}
              size="small"
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <DataTableDetail
            data={data.dataInvoice}
            columns={cashinInvoiceColumns()}
            fetchList={cashOut.fetchListInvoice}
            params={data.paramsDataInvoice}
            pagination={data.paginationDataInvoice}
            loading={data.isLoadingInvoice}
            isCombinedSelected={true}
            selectedData={listSelected}
            isPagination
            height={'50vh'}
            isSelection
            showTotal
            onSelect={(val) => onSelectTable(val)}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        {listSelected.length > 0 && (
          <Typography variant="subtitle1" component="p" style={{ marginRight: 24 }} color="primary">
            Terpilih: {listSelected.length}
          </Typography>
        )}
        <Button onClick={handleClose} variant="outlined" type="button">
          CLOSE
        </Button>
        <LoadingButton
          loading={loadingSubmit}
          type="button"
          variant="contained"
          onClick={handleSubmit}
          disabled={isButtonSelectDisable}>
          SELECT
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CashinInvoiceDialog;

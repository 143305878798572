import { BorderColor, Delete } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

export const columns = (
  role: any,
  handleEdit: (data: any) => void,
  handleDelete: (data: any) => void,
  history: any,
): GridColDef[] => {
  return [
    { field: 'name', headerName: 'Company Name', minWidth: 200, flex: 1 },
    { field: 'code', headerName: 'Company Code', minWidth: 200, flex: 1 },
    { field: 'alias', headerName: 'Alias', minWidth: 200, flex: 1 },
    {
      field: 'parent_company_id',
      headerName: 'Subsidiary',
      minWidth: 200,
      flex: 1,
      renderCell: ({ row, value }) => (value === null || value === row.id ? 'No' : 'Yes'),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      flex: 1,
      renderCell: (data) => (data.row.status ? 'Active' : 'Non Active'),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (data) => (
        <Grid container>
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="primary" />
            </Tooltip>
          )}
          {/* {role.view && <Tooltip title="History" sx={{ mr: 1 }} onClick={() => history.push(`company/${data.id}/history`)}>
            <History fontSize="small" className="pointer" color="info" />
          </Tooltip>} */}
          {role.delete && (
            <Tooltip title="Delete">
              <Delete
                fontSize="small"
                className="pointer"
                onClick={() => handleDelete(data.row)}
                color="error"
              />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};

import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';
import { actionType } from '../../actions/cash-in';

const initState = {
  ...baseInitState,
  dataDetail: [],
  paginationDataDetail: {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    recordPerPage: 5,
  },
  paramsDataDetail: {
    page: 1,
    limit: 10,
    order: 'created_at',
    sort: 'desc',
  },
};

export default function reducer(state = initState, action: any) {
  switch (action.type) {
    case actionType('fetchDetailItem').request:
      return {
        ...state,
        isLoadingDetail: true,
      };
    case actionType('fetchDetailItem').success:
      return {
        ...state,
        isLoadingDetail: false,
        dataDetail: action.response.data || [],
        paginationDataDetail: {
          currentPage: action.response?.pagination?.page || 1,
          lastPage: action.response?.pagination?.total_page || 1,
          count: action.response?.pagination?.total_data || 0,
          recordPerPage: action.response?.pagination?.limit || 10,
        },
        paramsDataDetail: action.params,
      };
    case actionType('fetchDetailItem').error:
      return {
        ...state,
        isLoadingDetail: false,
        dataDetail: null,
      };
    case actionType('postManualPaymentAt').request:
      return {
        ...state,
        isLoadingSubmit: true,
      };
    case actionType('postManualPaymentAt').success:
      return {
        ...state,
        isLoadingSubmit: false,
      };
    case actionType('postManualPaymentAt').error:
      return {
        ...state,
        isLoadingSubmit: false,
      };
    default:
      return baseReducer(state, action, actionType);
  }
}

import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object, string } from 'yup';

export interface IProductNewForm {
  product_name: string;
  code: string;
  company_id: string;
  cashout_fee_fix_value: number;
  cashout_fee_percentage: number;
  provider: Array<any>;
}

export const defaultValues = {
  product_name: '',
  code: '',
  company_id: '',
  cashout_fee_fix_value: 0,
  cashout_fee_percentage: 0,
  provider: [],
  status: true,
};

const providerChannel = object({
  provider_channel_id: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Required field'),
  fee_fix_value: string().required('Required field'),
  fee_percentage: string().required('Required field'),
});

const provider = object({
  provider_id: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Required field'),
  provider_channel: array().of(providerChannel),
});

export const resolver = yupResolver(
  object({
    product_name: string().required('Required field'),
    code: string().required('Required field').min(4, 'Minimum 4 characters'),
    company_id: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
    cashout_fee_percentage: number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .min(0, 'Must be greater than or equal to 0')
      .max(100, 'Must be less than or equal to 100')
      .required('Required field'),
    cashout_fee_fix_value: number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .min(0, 'Must be greater than or equal to 0')
      .required('Required field'),
    provider: array().of(provider),
  }),
);

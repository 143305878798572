import { Add, Search } from '@mui/icons-material';
import { Button, Grid, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import ModalDelete from '../../components/modal/ModalDelete';
import DataTable from '../../components/table/DataTable';
import company from '../../redux/actions/company';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import FormDialog from './FormDialog';
import { columns } from './models/columns';

const CompanyIndex = ({ history, role }: any) => {
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataSelected, setDataSelected] = useState({});
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.company);

  useEffect(() => {
    dispatch(company.fetchList(data.params));
    return () => dispatch(company.resetParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setDataSelected({});
    setOpenForm(true);
  };
  const handleEdit = (data: any) => {
    setDataSelected(data);
    setOpenForm(true);
  };
  const handleDelete = (data: any) => {
    setDataSelected(data);
    setOpenDelete(true);
  };
  const handleSearch = debounce((e) => {
    dispatch(company.fetchList({ ...data.params, page: 1, key: null, value: e.target.value }));
  }, 500);

  return (
    <Box>
      <Box component="h1" sx={{ mt: 0, fontSize: [22, 28, 32] }}>
        Company
      </Box>
      <Grid container>
        <OutlinedInput
          sx={{ mr: 1, mb: 1 }}
          onChange={handleSearch}
          placeholder="Search"
          endAdornment={<Search color="disabled" />}
          size="small"
        />
        {role.create && (
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleAdd()}>
            Add
          </Button>
        )}
      </Grid>
      <DataTable
        columns={columns(role, handleEdit, handleDelete, history)}
        data={data}
        height="60vh"
        fetchList={company.fetchList}
      />
      <FormDialog open={openForm} handleClose={() => setOpenForm(false)} data={dataSelected} />
      <ModalDelete
        open={openDelete}
        dataSelected={dataSelected}
        handleClose={() => setOpenDelete(false)}
        deleteData={company.deleteData}
        fetchList={company.fetchList}
        data={data}
        forbiddenError
        messageForbiddenError="Data sedang digunakan pada data lain. Silakan cek pada menu User, Product, Partner, Company Provider, dan field Subsidiary."
      />
    </Box>
  );
};

export default CompanyIndex;

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch } from '../../redux/store';
import { Delete } from '@mui/icons-material';
import { alertError } from '../../utils/alert';

const ModalDelete = ({
  dataSelected,
  open,
  handleClose,
  deleteData,
  fetchList,
  label = 'data',
  data,
  forbiddenError = false,
  messageForbiddenError = '',
  callback,
}: any) => {
  const dispatch = useAppDispatch();
  const handleDelete = () => {
    if (!deleteData) {
      return;
    }
    dispatch(deleteData(dataSelected?.id)).then(
      (res: any) => {
        handleClose();
        dispatch(fetchList(data.params));
        if (callback) callback();
      },
      (error: any) => {
        if (!forbiddenError) return alertError('Maaf, terjadi kesalahan');
        if (error.status === 403) return alertError(messageForbiddenError);

        return alertError('Maaf, terjadi kesalahan');
      },
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this {label}?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleClose} variant="outlined" type="button">
            Cancel
          </Button>
          <LoadingButton
            color="error"
            loadingPosition="start"
            loading={data?.isLoadingDelete}
            startIcon={<Delete />}
            onClick={handleDelete}
            variant="contained">
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDelete;

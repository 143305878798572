import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';
import { actionType } from '../../actions/cash-out';

const initState = {
  ...baseInitState,
  dataQR: '',
  isLoadingGenerate: false,
  isLoadingDelete: false,
  isLoadingItems: false,
  isLoadingInvoice: false,
  dataItems: [],
  dataInvoice: [],
  paginationDataItems: {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    recordPerPage: 10,
  },
  paginationDataInvoice: {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    recordPerPage: 10,
  },
  paramsDataInvoice: {
    page: 1,
    limit: 10,
    order: 'created_at',
    sort: 'desc',
  },
  paramsDataItems: {
    page: 1,
    limit: 10,
    order: 'created_at',
    sort: 'desc',
  },
};

export default function reducer(state = initState, action: any) {
  switch (action.type) {
    case actionType('generateQRCode').request:
      return {
        ...state,
        isLoadingGenerate: true,
      };
    case actionType('generateQRCode').success:
      return {
        ...state,
        dataQR: action.response.data?.qr,
        isLoadingGenerate: false,
      };
    case actionType('generateQRCode').error:
      return {
        ...state,
        isLoadingGenerate: false,
      };
    case actionType('fetchItems').request:
      return {
        ...state,
        isLoadingItems: true,
      };
    case actionType('fetchItems').success:
      return {
        ...state,
        dataItems: action.response?.data || [],
        paginationDataItems: {
          currentPage: action.response?.pagination?.page || 1,
          lastPage: action.response?.pagination?.total_page || 1,
          count: action.response?.pagination?.total_data || 0,
          recordPerPage: action.response?.pagination?.limit || 10,
        },
        paramsDataItems: action.params,
        isLoadingItems: false,
      };
    case actionType('fetchItems').error:
      return {
        ...state,
        isLoadingItems: false,
      };
    case actionType('fetch-invoice').request:
      return {
        ...state,
        isLoadingInvoice: true,
      };
    case actionType('fetch-invoice').success:
      return {
        ...state,
        dataInvoice: action.response?.data || [],
        paginationDataInvoice: {
          currentPage: action.response?.pagination?.page || 1,
          lastPage: action.response?.pagination?.total_page || 1,
          count: action.response?.pagination?.total_data || 0,
          recordPerPage: action.response?.pagination?.limit || 10,
        },
        paramsDataInvoice: action.params,
        isLoadingInvoice: false,
      };
    case actionType('fetch-invoice').error:
      return {
        ...state,
        isLoadingInvoice: false,
      };
    case actionType('delete-invoice').request:
      return {
        ...state,
        isLoadingDelete: true,
      };
    case actionType('delete-invoice').success:
      return {
        ...state,
        isLoadingDelete: false,
      };
    case actionType('delete-invoice').error:
      return {
        ...state,
        isLoadingDelete: false,
      };
    case actionType('resetParamsDataItems').success:
      return {
        ...state,
        paramsDataItems: initState.paramsDataItems,
        paginationDataItems: initState.paginationDataItems,
      };
    case actionType('resetParamsDataInvoice').success:
      return {
        ...state,
        paramsDataInvoice: initState.paramsDataInvoice,
        paginationDataInvoice: initState.paginationDataInvoice,
      };

    default:
      return baseReducer(state, action, actionType);
  }
}

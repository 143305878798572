import { BorderColor, Delete } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { formatMoney } from '../../../utils/string';

export const columns = (
  role: any,
  handleEdit: (data: any) => void,
  handleDelete: (data: any) => void,
  history: any,
): GridColDef[] => {
  return [
    { field: 'company_name', headerName: 'Company Name', flex: 1, minWidth: 200 },
    { field: 'product_name', headerName: 'Product Name', flex: 1, minWidth: 200 },
    { field: 'product_code', headerName: 'Product Code', flex: 1, minWidth: 150 },
    // {
    //     field: 'fee_fix_value',
    //     headerName: 'Product Fee (Rp)',
    //     flex: 1,
    //     minWidth: 150,
    //     renderCell: ({value}) => formatMoney(value)
    // },
    // {
    //     field: 'fee_percentage',
    //     headerName: 'Product Fee (%)',
    //     flex: 1,
    //     minWidth: 150,
    //     renderCell: ({value}) => value + '%'
    // },
    {
      field: 'cashout_fee_fix_value',
      headerName: 'Product Fee (Rp)',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => formatMoney(value),
    },
    {
      field: 'cashout_fee_percentage',
      headerName: 'Product Fee (%)',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => value + '%',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (data) => (data.row.status ? 'Active' : 'Non Active'),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (data) => (
        <Grid container>
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="primary" />
            </Tooltip>
          )}
          {role.delete && (
            <Tooltip title="Delete">
              <Delete
                fontSize="small"
                className="pointer"
                onClick={() => handleDelete(data.row)}
                color="error"
              />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};

//@typescript-eslint/no-unused-vars: 0
import { ArrowBack } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import DataTable from '../../../components/table/DataTable';
import PartnerHistoryDetail from './PartnerHistoryDetail';

const PartnerHistoryIndex = ({ history }: any) => {
  const columns: GridColDef[] = [
    { field: 'batch_no', headerName: 'Batch Number', flex: 1 },
    { field: 'disb_date', headerName: 'Disbursement Date', flex: 1 },
    { field: 'provider', headerName: 'Payment Provider', flex: 1 },
    { field: 'channel', headerName: 'Payment Channel', flex: 1 },
    { field: 'bank_account', headerName: 'Bank Account Receiver', flex: 1 },
    { field: 'fee', headerName: 'Trannsaction Fee', flex: 1 },
    { field: 'total_fee', headerName: 'Total Amount', flex: 1 },
    { field: 'status', headerName: 'status', flex: 1 },
  ];
  const data = [
    {
      id: 1,
      batch_no: 'CO/RBK/01',
      disb_date: '12 Desember 2021 09:00 WIB',
      provider: 'Xfer',
      channel: 'BCA',
      bank_account: '121211221',
      fee: 20000,
      total_fee: 1200000,
      status: 'Success',
    },
    {
      id: 2,
      batch_no: 'CO/RBK/01',
      disb_date: '12 Desember 2021 09:00 WIB',
      provider: 'Xfer',
      channel: 'BCA',
      bank_account: '121211221',
      fee: 20000,
      total_fee: 1200000,
      status: 'Success',
    },
    {
      id: 3,
      batch_no: 'CO/RBK/01',
      disb_date: '12 Desember 2021 09:00 WIB',
      provider: 'Xfer',
      channel: 'BCA',
      bank_account: '121211221',
      fee: 20000,
      total_fee: 1200000,
      status: 'Success',
    },
    {
      id: 4,
      batch_no: 'CO/RBK/01',
      disb_date: '12 Desember 2021 09:00 WIB',
      provider: 'Xfer',
      channel: 'BCA',
      bank_account: '121211221',
      fee: 20000,
      total_fee: 1200000,
      status: 'Success',
    },
    {
      id: 5,
      batch_no: 'CO/RBK/01',
      disb_date: '12 Desember 2021 09:00 WIB',
      provider: 'Xfer',
      channel: 'BCA',
      bank_account: '121211221',
      fee: 20000,
      total_fee: 1200000,
      status: 'Success',
    },
  ];
  const [dataSelected, setDataSelected] = useState();

  return (
    <Box>
      <Box component="h2">Partner History - Siswa Media</Box>
      <Grid container sx={{ mb: 1 }}>
        <Button variant="contained" startIcon={<ArrowBack />} onClick={() => history.goBack()}>
          Back
        </Button>
      </Grid>
      <DataTable
        columns={columns}
        data={data}
        height="45vh"
        disableSelectionOnClick={false}
        onRowClick={({ row }) => setDataSelected(row)}
      />
      {dataSelected && <PartnerHistoryDetail />}
    </Box>
  );
};

export default PartnerHistoryIndex;

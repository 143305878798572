import { Box } from '@mui/system';
import { useEffect } from 'react';
import DataTable from '../../components/table/DataTable';
import cashInHistory from '../../redux/actions/cash-in-history';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { cashInHistoryColumns } from './models/columns';

const CashInHistory = ({ dataSelected }: any) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.cashInHistory);

  useEffect(() => {
    if (dataSelected) {
      dispatch(cashInHistory.fetchList({ ...data.params, id: dataSelected.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelected]);

  return (
    <Box>
      <Box component="h2" sx={{ textTransform: 'capitalize' }}>
        Transaction Activity Log
      </Box>
      <DataTable
        columns={cashInHistoryColumns()}
        data={data}
        height="45vh"
        fetchList={cashInHistory.fetchList}
      />
    </Box>
  );
};

export default CashInHistory;

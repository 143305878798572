import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ISNAPForm, SNAPResolver } from './models/form';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { InputDateTimeField } from '../../components/fields/RenderField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Send } from '@mui/icons-material';
import { useAppSelector } from '../../redux/store';

const CashInSnapDialog = ({ open, handleClose, onSubmit }: any) => {
  const methods = useForm<ISNAPForm>({ resolver: SNAPResolver });

  const loading = useAppSelector((state) => state.cashIn.isLoadingSubmit);
  const loadingDetail = useAppSelector((state) => state.cashIn.isLoadingDetail);
  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown') {
            handleClose();
          } else {
            handleClose();
          }
        }}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">SNAP Paid Manual</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <InputDateTimeField
                    name="payment_at"
                    label="Actual Payment Date"
                    format="YYYY/MM/DD HH:mm:ss"
                    disableFuture
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading || loadingDetail}
              variant="contained"
              endIcon={<Send />}
              loadingPosition="end"
              type="submit">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CashInSnapDialog;

import { formatCapitalize } from '../../../utils/string';
import { channelType } from './enum';

export const setNameChannelType = (val: string) => {
  if (Object(channelType).hasOwnProperty(val)) {
    return channelType[val];
  }

  return formatCapitalize(val);
};

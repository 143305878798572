import jwt_decode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';

interface IToken {
  isValid: boolean;
  expiredTime: number;
  expiredDate: moment.Moment;
  isExpired: boolean;
}

export const checkToken = (token: string): IToken => {
  try {
    const decoded = jwt_decode<JwtPayload>(token);
    const { exp } = decoded;
    if ((exp as number) > moment().unix()) {
      return {
        isValid: true,
        expiredTime: exp as number,
        expiredDate: moment.unix(exp as number),
        isExpired: false,
      };
    }
    return {
      isValid: true,
      expiredTime: exp as number,
      expiredDate: moment.unix(exp as number),
      isExpired: true,
    };
  } catch (e) {
    return {
      isValid: false,
      expiredTime: 0,
      expiredDate: moment(),
      isExpired: true,
    };
  }
};

import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

export interface IRoleForm {
  name: string;
  code: string;
  description: string;
  permissions: any[];
  status: boolean;
}

export const defaultValues = {
  name: '',
  code: '',
  description: '',
  permissions: [
    {
      id: 0,
      menu_id: 1,
      name: 'Company',
      menu_code: 'company',
      permission: {
        create: false,
        delete: false,
        edit: false,
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 2,
      name: 'Partner',
      menu_code: 'partner',
      permission: {
        create: false,
        delete: false,
        edit: false,
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 3,
      name: 'Product',
      menu_code: 'product',
      permission: {
        create: false,
        delete: false,
        edit: false,
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 4,
      name: 'Channel',
      menu_code: 'channel',
      permission: {
        create: false,
        delete: false,
        edit: false,
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 5,
      name: 'Provider Channel',
      menu_code: 'provider_channel',
      permission: {
        create: false,
        delete: false,
        edit: false,
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 6,
      name: 'Company Provider',
      menu_code: 'company_provider',
      permission: {
        create: false,
        delete: false,
        edit: false,
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 8,
      name: 'Cash In',
      menu_code: 'cash_in',
      permission: {
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 9,
      name: 'Cash Out',
      menu_code: 'cash_out',
      permission: {
        approve: false,
        reject: false,
        transfer: false,
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 10,
      name: 'User',
      menu_code: 'user',
      permission: {
        create: false,
        delete: false,
        edit: false,
        view: false,
      },
    },
    {
      id: 0,
      menu_id: 11,
      name: 'Role',
      menu_code: 'role',
      permission: {
        create: false,
        delete: false,
        edit: false,
        view: false,
      },
    },
  ],
  status: true,
};

export const resolver = yupResolver(
  object({
    name: string().required('Required field'),
    code: string().required('Required field'),
  }),
);

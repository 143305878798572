import { Box, CssBaseline, Paper } from '@mui/material';
import { Fragment } from 'react';
import { InputField, InputPasswordField } from '../../components/fields/RenderField';
import { FormProvider, useForm } from 'react-hook-form';
import { ILoginForm, loginResolver } from './models/form';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import auth from '../../redux/actions/auth';
import { isAuth } from '../../utils/token';
import { Redirect } from 'react-router-dom';
import { alertError } from '../../utils/alert';

const Login = ({ history }: any) => {
  const methods = useForm<ILoginForm>({ resolver: loginResolver });
  const dispatch = useAppDispatch();

  const dataAuth = useAppSelector((state) => state.auth);

  const onSubmit = (value: ILoginForm) => {
    const formData = {
      email: value.email,
      password: value.password,
    };
    dispatch(auth.login({ login: JSON.stringify(formData) }))
      .then(() => {
        history.push('/home');
      })
      .catch((err) => {
        if (err?.data?.message === 'email not valid') {
          methods.setError('email', { message: 'Email not found' });
        } else if (err?.data?.message === 'wrong password') {
          methods.setError('password', { message: 'Wrong password' });
        } else {
          alertError(err?.data?.message);
        }
      });
  };

  if (isAuth()) {
    return <Redirect to="/home" />;
  }

  return (
    <Fragment>
      <CssBaseline />
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f9f0',
          p: 4,
        }}>
        <Paper
          elevation={6}
          sx={{ minHeight: '380px', minWidth: { sm: '400px' }, borderRadius: 3, p: 3 }}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box component="h1" textAlign="center">
              LOGIN
            </Box>
            <FormProvider {...methods}>
              <InputField name="email" label="Email" />
              <InputPasswordField name="password" label="Password" />
              <Box sx={{ mt: 5 }}>
                <LoadingButton
                  fullWidth
                  loading={dataAuth.isLoading}
                  type="submit"
                  variant="contained">
                  Login
                </LoadingButton>
              </Box>
            </FormProvider>
          </form>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default Login;

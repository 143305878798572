import { ArrowBack } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import DataTable from '../../../components/table/DataTable';

const CompanyHistoryIndex = ({ history }: any) => {
  const columns: GridColDef[] = [
    { field: 'trx_date', headerName: 'Transaction Date', flex: 1 },
    { field: 'trx_type', headerName: 'Transaction Type', flex: 1 },
    { field: 'desc', headerName: 'Description', flex: 1 },
  ];
  const data = [
    {
      id: 1,
      trx_date: '12 Desember 2021 09:00 WIB',
      trx_type: 'Debit',
      desc: 'Pembayaran kepada merchant',
    },
    {
      id: 2,
      trx_date: '12 Desember 2021 09:00 WIB',
      trx_type: 'Debit',
      desc: 'Pembayaran kepada merchant',
    },
    {
      id: 3,
      trx_date: '12 Desember 2021 09:00 WIB',
      trx_type: 'Debit',
      desc: 'Pembayaran kepada merchant',
    },
    {
      id: 4,
      trx_date: '12 Desember 2021 09:00 WIB',
      trx_type: 'Debit',
      desc: 'Pembayaran kepada merchant',
    },
    {
      id: 5,
      trx_date: '12 Desember 2021 09:00 WIB',
      trx_type: 'Debit',
      desc: 'Pembayaran kepada merchant',
    },
    {
      id: 6,
      trx_date: '12 Desember 2021 09:00 WIB',
      trx_type: 'Debit',
      desc: 'Pembayaran kepada merchant',
    },
  ];
  return (
    <Box>
      <Box component="h2">Company History - Siswa Media</Box>
      <Grid container sx={{ mb: 1 }}>
        <Button variant="contained" startIcon={<ArrowBack />} onClick={() => history.goBack()}>
          Back
        </Button>
      </Grid>
      <DataTable columns={columns} data={data} height="60vh" />
    </Box>
  );
};

export default CompanyHistoryIndex;

import httpService from '../../../adapters/httpService';
import { URL_API } from '../../../configs';
import baseActions from '../baseActions';

const path = 'cashin';

export const actionType = (action: string) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const http = httpService();
const index = {
  ...baseActions(path, URL_API, actionType),
  fetchDetailItem: (params: any) => {
    const http = httpService();
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchDetailItem').request });
      return http.get(`${URL_API}/cashin/${params.id}/items`, { params }).then(
        (response) => {
          dispatch({
            type: actionType('fetchDetailItem').success,
            response: response.data,
            params,
          });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchDetailItem').error });
          throw error.response;
        },
      );
    };
  },
  postReTryItem: (id: string) => {
    const http = httpService();
    return http.post(`${URL_API}/cashin/retryCallback/${id}`).then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error.response;
      },
    );
  },
  fetchDetailV2: (id: string) => {
    const http = httpService();
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchDetail').request });
      return http.get(`${URL_API}/cashin/${id}/detail`).then(
        (response) => {
          dispatch({ type: actionType('fetchDetail').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchDetail').error });
          throw error.response;
        },
      );
    };
  },
  export: (params: any) => {
    const http = httpService();
    return http.get(`${URL_API}/cashin/export`, { params, responseType: 'arraybuffer' }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error.response;
      },
    );
  },
  postManualPaymentAt: (id: string, data: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('postManualPaymentAt').request });
      return http.post(`${URL_API}/cashin/manualPayment/${id}`, data).then(
        (response) => {
          dispatch({ type: actionType('postManualPaymentAt').success });
          return response;
        },
        (error) => {
          dispatch({ type: actionType('postManualPaymentAt').error });
          throw error;
        },
      );
    };
  },
};
export default index;

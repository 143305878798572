import httpService from '../../../adapters/httpService';
import { URL_API } from '../../../configs';
import baseActions from '../baseActions';

const path = 'payment-channel';

export const actionType = (action: string) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};
const index = {
  ...baseActions(path, URL_API, actionType),
  fetchAll: (params?: any) => {
    const http = httpService();
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchAll').request });
      return http.get(`${URL_API}/payment-channels`, { params: { ...params, status: true } }).then(
        (response) => {
          dispatch({ type: actionType('fetchAll').success, response: response.data });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchAll').error });
          throw error.response;
        },
      );
    };
  },
  getChannelType: (params?: any) => {
    const http = httpService();
    return (dispatch: any) => {
      dispatch({ type: actionType('getChannelType').request });
      return http
        .get(`${URL_API}/payment-channels/types`, { params: { ...params, status: true } })
        .then(
          (response) => {
            dispatch({ type: actionType('getChannelType').success, response: response.data });
            return response.data;
          },
          (error) => {
            dispatch({ type: actionType('getChannelType').error });
            throw error.response;
          },
        );
    };
  },
  fetchAllList: (params?: any) => {
    const http = httpService();
    return http.get(`${URL_API}/payment-channels`, { params: { ...params, status: true } }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error.response;
      },
    );
  },
};
export default index;

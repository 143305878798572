import httpService from '../../../adapters/httpService';
import { URL_API } from '../../../configs';
import baseActions from '../baseActions';

const path = 'cashin-history';

export const actionType = (action: string) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};
const index = {
  ...baseActions(path, URL_API, actionType),
  fetchList: (params?: any) => {
    const http = httpService();
    return (dispatch: any) => {
      dispatch({ type: actionType('fetchList').request });
      return http.get(`${URL_API}/cashin/${params.id}/activity`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchList').success, response: response.data, params });
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('fetchList').error });
          throw error.response;
        },
      );
    };
  },
};

export default index;

import { ExpandMore, Key } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { CheckboxGroupField, CheckboxField } from '../../../../components/fields/RenderField';

interface Props {
  data: {
    id: number;
    menu_id: number;
    name: string;
    menu_code: string;
    permission: {
      create: boolean;
      delete: boolean;
      edit: boolean;
      view: boolean;
    };
  };
}

const PermissionMenu: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <Accordion
      sx={{
        border: (theme) => `1px solid ${theme.palette.action.disabled}`,
        borderRadius: 1,
        '&:before': { display: 'none' },
        color: 'gray',
        mb: 2,
      }}
      defaultExpanded={!data?.id}
      elevation={0}
      disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ minHeight: '24px', px: '14px', '.MuiAccordionSummary-content': { my: '8px' } }}>
        {data.name}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {Object.entries(data.permission).map((val) => (
            <CheckboxField
              label={val[0]}
              name={`${data.menu_code}-${val[0]}`}
              defaultValue={val[1]}
            />
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PermissionMenu;

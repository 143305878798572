import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import DataTable from '../../components/table/DataTable';
import { useAppSelector } from '../../redux/store';
import { cashInColumns } from './models/columns';
import CashInHistory from './CashInHistory';
import CashInDetailDialog from './CashInDetailDialog';
import cashIn from '../../redux/actions/cash-in';
import CashInFilter from './CashInFilter';
import { useAppDispatch } from '../../redux/store';
import { alertError, alertSuccess } from '../../utils/alert';
import CashInSnapDialog from './CashInSnapDialog';
import { formatISO } from '../../utils/time';

const CashInIndex = ({ history }: any) => {
  const dispatch = useAppDispatch();
  const [dataSelected, setDataSelected] = useState();
  const [openDetail, setOpenDetail] = useState(false);
  const [openPaidManual, setOpenPaidManual] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [detailCashIn, setDetailCashIn] = useState<any>();
  const data = useAppSelector((state) => state.cashIn);
  const params = useAppSelector((state) => state.cashIn.params);
  const loadingCompany = useAppSelector((state) => state.company.isLoading);

  useEffect(() => {
    setOpenHistory(false);
  }, [data.dataList]);

  const handleHistory = (data: any) => {
    setDataSelected(data);
    setOpenHistory(true);
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 300);
  };

  const handleDetail = async (values: any) => {
    try {
      setDetailCashIn(values);
      const { data } = await dispatch(cashIn.fetchDetailV2(values.id));
      setDataSelected({ ...values, ...data });
      setOpenHistory(false);
      setOpenDetail(true);
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  const handleReTry = async (values: any) => {
    try {
      const { code } = await cashIn.postReTryItem(values.id);
      if (code === 200) {
        return alertSuccess(`Retry berhasil`);
      }
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  const handleOpenPaidManual = () => {
    setOpenPaidManual(true);
  };

  const handleClosePaidManual = () => {
    setOpenPaidManual(false);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const onSubmitSnap = async (value: any) => {
    try {
      value.payment_at = formatISO(value.payment_at);

      await dispatch(cashIn.postManualPaymentAt(detailCashIn.id, value));
      const { data } = await dispatch(cashIn.fetchDetailV2(detailCashIn.id));
      setDataSelected({ ...detailCashIn, ...data });

      alertSuccess(`Cashin berhasil dipaid manual`).then(async () => {
        handleClosePaidManual();
        dispatch(cashIn.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  return (
    <Box>
      <Box component="h1" sx={{ mt: 0, fontSize: [22, 28, 32] }}>
        Cash In Transactions
      </Box>
      <CashInFilter />
      <DataTable
        columns={cashInColumns(handleHistory, handleDetail, handleReTry)}
        data={data}
        height="75vh"
        fetchList={cashIn.fetchList}
        loading={loadingCompany || data.isLoading}
        showTotal
      />
      <CashInDetailDialog
        open={openDetail}
        handleClose={handleCloseDetail}
        handlePaidManual={handleOpenPaidManual}
        dataSelected={dataSelected}
      />
      <CashInSnapDialog
        open={openPaidManual}
        handleClose={handleClosePaidManual}
        onSubmit={onSubmitSnap}
      />
      {openHistory && <CashInHistory dataSelected={dataSelected} />}
    </Box>
  );
};

export default CashInIndex;

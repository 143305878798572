import httpService from '../adapters/httpService';
import { URL_API } from '../configs';

export const uploadImage = async (data: any) => {
  try {
    const res = await httpService().post('file/upload', data, { baseURL: URL_API });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string } from 'yup';

export interface IPartnerForm {
  company_code: string;
  partner: string;
  partner_code: string;
  phone: string;
  email: string;
  payment_channel_id: string;
  bank_account_number: string;
  account_receivable: string;
  sla: string;
  fee_behind: boolean;
  status: boolean;
}

export const defaultValues = {
  company_code: '',
  partner: '',
  partner_code: '',
  phone: '',
  email: '',
  payment_channel_id: '',
  bank_account_number: '',
  account_receivable: '',
  sla: '',
  fee_behind: false,
  status: true,
};

export const resolver = yupResolver(
  object({
    company_code: string().required('Required field'),
    partner: string().required('Required field'),
    partner_code: string().required('Required field').min(4, 'Minimum 4 characters'),
    phone: string().required('Required field'),
    account_receivable: string().required('Required field'),
    bank_account_number: string().required('Required field').max(16, 'Maximum 16 characters'),
    email: string().required('Required field').email(),
    payment_channel_id: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
    sla: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field')
      .min(0, 'Must be greater than or equal to 0'),
  }),
);

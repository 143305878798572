import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridRowParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAppDispatch } from '../../redux/store';
import { Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  columns: GridColDef[];
  data: any;
  isSelection?: boolean;
  onSelect?: (e: GridSelectionModel, x?: GridCallbackDetails) => void;
  onRowClick?: (e: any) => void;
  height?: string;
  isPagination?: boolean;
  disableSelectionOnClick?: boolean;
  fetchList?: any;
  loading?: boolean;
  isRowSelectable?: (params: GridRowParams<{ [key: string]: any }>) => boolean;
  showTotal?: boolean;
  pagination?: {
    count: number;
    currentPage: number;
    lastPage: number;
    recordPerPage: number;
  };
  params?: {
    page: number;
    limit: number;
    order: string;
    sort: string;
  };
  isCombinedSelected?: boolean;
  selectedData?: any;
}

export default function DataTableDetail(props: Props): JSX.Element {
  const {
    isPagination = true,
    disableSelectionOnClick = true,
    showTotal,
    pagination,
    params,
  } = props;
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<any[]>(
    pagination && params ? props.data : props.data?.dataDetail || props.data || [],
  );
  const [selectedData, setSelectedData] = useState<GridSelectionModel>([]);

  const tablePagination = pagination || props.data?.paginationDataDetail;
  const paramsPagination = params || props.data?.paramsDataDetail;

  const onPaginationChange = (page: number, limit: number) => {
    if (props.fetchList) {
      dispatch(props.fetchList({ ...paramsPagination, page, limit }));
    }
  };

  const getCurrentPageIds = () => {
    return rows.map((row: any) => row.id);
  };

  const handleSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    const currentPageIds = getCurrentPageIds();

    // Get the IDs of the selected rows on the current page
    const selectedCurrentPageIds = newSelectionModel.filter((id) => currentPageIds.includes(id));

    // Get the IDs of the selected rows on other pages
    const selectedOtherPageIds = selectedData.filter((id) => !currentPageIds.includes(id));

    const newSelectedData = [...selectedOtherPageIds, ...selectedCurrentPageIds];

    // Merge the two arrays and update the state
    setSelectedData(newSelectedData);

    props.onSelect?.(newSelectedData);
  };

  const onSortTable = (cell: Array<any>) => {
    if (!props.fetchList) return;

    if (cell.length) {
      dispatch(
        props.fetchList({
          ...paramsPagination,
          order: cell[0].field,
          sort: cell[0].sort,
        }),
      );
    } else {
      dispatch(props.fetchList({ ...paramsPagination, order: 'created_at', sort: 'desc' }));
    }
  };
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    if (filterModel.items.length > 0) {
      dispatch(
        props.fetchList({
          ...paramsPagination,
          key: filterModel.items[0].columnField,
          value: filterModel.items[0].value,
        }),
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedData([]);
  }, []);

  useEffect(() => {
    setRows(pagination && params ? props.data : props.data?.dataDetail || props.data || []);
  }, [props.data, pagination, params]);

  useEffect(() => {
    setSelectedData(props.selectedData);
  }, [props.selectedData]);

  return (
    <div style={{ display: 'flex', height: props.height }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows}
          autoHeight={!props.height}
          rowHeight={40}
          columns={props.columns}
          sortingMode={!!props.fetchList ? 'server' : 'client'}
          onSortModelChange={onSortTable}
          checkboxSelection={props.isSelection}
          isRowSelectable={
            props.isCombinedSelected
              ? (params) => {
                  const currentPageIds = rows.map((row: any) => row.id);
                  // Only allow selection of rows on the current page
                  return currentPageIds.includes(params.id);
                }
              : props.isRowSelectable
          }
          disableSelectionOnClick={disableSelectionOnClick}
          onRowClick={props.onRowClick}
          onSelectionModelChange={
            props.isCombinedSelected ? handleSelectionModelChange : props.onSelect
          }
          selectionModel={
            props.isCombinedSelected
              ? selectedData.filter((id) => rows.some((row: any) => row.id === id))
              : undefined
          }
          filterMode={!!props.fetchList ? 'server' : 'client'}
          onFilterModelChange={onFilterChange}
          loading={props.loading || props.data?.isLoadingDetail}
          components={{
            Footer: () =>
              isPagination ? (
                <Grid
                  container
                  alignItems="center"
                  justifyContent={showTotal ? 'space-between' : 'end'}
                  borderTop={'1px solid rgba(224, 224, 224, 1)'}
                  p={1}>
                  {showTotal && (
                    <Typography variant="inherit">Total: {tablePagination?.count || 0}</Typography>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Rows per page:
                    <Select
                      size="small"
                      sx={{ mx: 1 }}
                      value={tablePagination?.recordPerPage || 5}
                      onChange={(e) => onPaginationChange(1, e.target.value)}>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                    <Pagination
                      sx={{ mt: [2, 0] }}
                      count={tablePagination?.lastPage}
                      page={tablePagination?.currentPage}
                      onChange={(e, val) => onPaginationChange(val, tablePagination?.recordPerPage)}
                      variant="outlined"
                      color="primary"
                    />
                  </div>
                </Grid>
              ) : (
                <div></div>
              ),
          }}
        />
      </div>
    </div>
  );
}

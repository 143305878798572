import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';
import { actionType } from '../../actions/company';

const initState = {
  ...baseInitState,
  companyChannels: [],
  companyProviders: [],
};

export default function reducer(state = initState, action: any) {
  switch (action.type) {
    case actionType('fetchCompanyChannels').success:
      return {
        ...state,
        companyChannels: action.response?.payload || [],
      };
    case actionType('fetchCompanyProviders').success:
      return {
        ...state,
        companyProviders: action.response?.payload || [],
      };

    default:
      return baseReducer(state, action, actionType);
  }
}

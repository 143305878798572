import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';
import { actionType } from '../../actions/provider';

const initState = {
  ...baseInitState,
  providerDefaults: [],
};

export default function reducer(state = initState, action: any) {
  switch (action.type) {
    case actionType('getProviderDefault').success:
      return {
        ...state,
        providerDefaults: action.response?.data || [],
      };

    default:
      return baseReducer(state, action, actionType);
  }
}
